import React from "react";




export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h1>Valor do Investimento</h1>
          <p>
            Tudo isso que mostramos acima pelo valor 
          </p>
          <p>
             De <del>R$350,00 </del>
          </p>
          <p >
             Por  R$ 247,00 
          </p>
          <p  className="paragrafo">
              EM ATÉ 12X  
          </p >
          <p  className="destaque">
             R$ 24,80
          </p>
          <br />
          <br />
          <a
                  href="https://pay.kiwify.com.br/pvEGyKV"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Faça sua inscrição !
          </a>{" "}
          <br />
          <br />
          <br />
          <a
                  href="https://wa.me/+5524988371135?text=Olá tudo bom? Estou interessada(o) no workshop, e gostaria de mais informações"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Quero mais informações!
          </a>{" "}

                
        </div>


        {/* <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
